// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:02+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            b2016e10440150fabe581afc13225585
//    Signature:      eyJpdiI6Ino4UXNHeGlmNWNReHFJSmVcL2Q5dXBBPT0iLCJ2YWx1ZSI6IjFaaVhtWk9veElGeTVReXoyd1prNTdLTUxHZXNva0s5XC9CeVpVMmxhMEZkYUlobkZHWDRSc2Zib3dNWWtsdjJiUU1sa1R0YnNpMXB4ZlF1S1l1WkhDNFJFd2ozRGw3WVlkc0RvV1lCeTVaVkF2UFJHQTlcL21nQ3JKY3VTZ21MUSticnVINEltZUJsQ0FsQW9kU1FZdUVxRk5HaWNxVHFGQ2hsRGRZZjRPSm1KbGFRUVE3QVZNYXhtVnpVM2pTXC82ZHUrT1FLNVVNeW54YnVnNEVMaTQzQW8wbXNINFFncEh2bmNoYVQ4OHNLb1wvT3pXN1wvUHc0Z3pqTVByXC9nTDI3WGJRaUsyTFBlVmcwOThDYWFEV2phSG9iY1FyejlBU1FISXRqK0hwR0RFWnlUNmt3NE9NNENtRWJiSFAzWm9SUUJkaldwVEhvRzdST25HMFdycUxQVEdrOFRjQXVpWVB0eG50TlwvMmR6Q1E0SDNaNmtRNW81U013NkNUXC8wSE5TMmpIaHhUQmlUeHVHZWs2RVVnRTBIb25kZz09IiwibWFjIjoiNDA0ZjI2YTg4YjU3NmY3NDVmYmRkMGI3ZjI1NmZiODJhYzY2YmU3NDM5ZjVhYzZlNWU2ZGYyZjgzNjRjZjljNiJ9
const selectors = {
  customerAddresses: '[data-customer-addresses]',
  addressCountrySelect: '[data-address-country-select]',
  addressContainer: '[data-address]',
  toggleAddressButton: 'button[aria-expanded]',
  cancelAddressButton: 'button[type="reset"]',
  deleteAddressButton: 'button[data-confirm-message]'
};

const attributes = {
  expanded: 'aria-expanded',
  confirmMessage: 'data-confirm-message'
};

class CustomerAddresses {
  constructor() {
    this.elements = this._getElements();
    if (Object.keys(this.elements).length === 0) return;
    this._setupCountries();
    this._setupEventListeners();

  }


  _getElements() {
    const container = document.querySelector(selectors.customerAddresses);
    return container ? {
      container,
      addressContainer: container.querySelector(selectors.addressContainer),
      toggleButtons: document.querySelectorAll(selectors.toggleAddressButton),
      cancelButtons: container.querySelectorAll(selectors.cancelAddressButton),
      deleteButtons: container.querySelectorAll(selectors.deleteAddressButton),
      countrySelects: container.querySelectorAll(selectors.addressCountrySelect)
    } : {};
  }

  _setupCountries() {
    if (Shopify && Shopify.CountryProvinceSelector) {
      // eslint-disable-next-line no-new
      new Shopify.CountryProvinceSelector('AddressCountryNew', 'AddressProvinceNew', {
        hideElement: 'AddressProvinceContainerNew'
      });
      this.elements.countrySelects.forEach((select) => {
        const formId = select.dataset.formId;
        // eslint-disable-next-line no-new
        new Shopify.CountryProvinceSelector(`AddressCountry_${formId}`, `AddressProvince_${formId}`, {
          hideElement: `AddressProvinceContainer_${formId}`
        });
      });
    }
  }

  _setupEventListeners() {
    this.elements.toggleButtons.forEach((element) => {
      element.addEventListener('click', this._handleAddEditButtonClick);
    });
    this.elements.cancelButtons.forEach((element) => {
      element.addEventListener('click', this._handleCancelButtonClick);
    });
    this.elements.deleteButtons.forEach((element) => {
      element.addEventListener('click', this._handleDeleteButtonClick);
    });
  }

  _toggleExpanded(target) {
    target.setAttribute(
      attributes.expanded,
      (target.getAttribute(attributes.expanded) === 'false').toString()
    );
  }

  _handleAddEditButtonClick = ({ currentTarget }) => {
    this._toggleExpanded(currentTarget);
  }

  _handleCancelButtonClick = ({ currentTarget }) => {
    this._toggleExpanded(
      currentTarget
        .closest(selectors.addressContainer)
        .querySelector(`[${attributes.expanded}]`)
    )
  }

  _handleDeleteButtonClick = ({ currentTarget }) => {
    // eslint-disable-next-line no-alert
    if (confirm(currentTarget.getAttribute(attributes.confirmMessage))) {
      Shopify.postLink(currentTarget.dataset.target, {
        parameters: { _method: 'delete' },
      });
    }
  }
}

window.onload = () => {
  typeof CustomerAddresses !== 'undefined' && new CustomerAddresses();
}
